/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 576px) {
  
  .div_footer_sheru_logo {
    justify-content: center;
  }

  .div_footer_social {
    justify-content: center;
  }

  .div_footer_copyright {
    justify-content: center;
  }

  .div_cover_about_sheru {
    margin-top: 45px;
  }

  .otherLinkInnerContainer {
    flex-direction: column; 
  }

  .p_gap_line {
    display: none;
  }

  .p_other_page_link {
    padding-top: 2.5px; 
    padding-bottom: 2.5px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .div_footer_sheru_logo {
    justify-content: center;
  }

  .div_footer_social {
    justify-content: center;
  }

  .div_footer_copyright {
    justify-content: center;
  }

  .div_cover_about_sheru {
    margin-top: 45px;
  }

  .otherLinkInnerContainer {
    flex-direction: column; 
  }

  .p_gap_line {
    display: none;
  }

  .p_other_page_link {
    padding-top: 2.5px; 
    padding-bottom: 2.5px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .div_footer_sheru_logo {
    justify-content: center;
  }

  .div_footer_social {
    justify-content: center;
  }

  .div_footer_copyright {
    justify-content: center;
  }

  .div_cover_about_sheru {
    margin-top: 35px;
  }

  .otherLinkInnerContainer {
    flex-direction: row; 
  }

  .p_gap_line {
    display: flex;
  }

  .p_other_page_link {
    padding-top: 0px; 
    padding-bottom: 0px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .div_footer_sheru_logo {
    justify-content: left;
  }

  .div_footer_social {
    justify-content: center;
  }
  
  .div_footer_copyright {
    justify-content: right;
  }

  .div_cover_about_sheru {
    margin-top: 5px;
  }

  .otherLinkInnerContainer {
    flex-direction: row; 
  }

  .p_gap_line {
    display: flex;
  }

  .p_other_page_link {
    padding-top: 0px; 
    padding-bottom: 0px;
  }
}

 /* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .div_footer_sheru_logo {
    justify-content: left;
  }

  .div_footer_social {
    justify-content: center;
  }
  
  .div_footer_copyright {
    justify-content: right;
  }

  .div_cover_about_sheru {
    margin-top: 5px;
  }

  .otherLinkInnerContainer {
    flex-direction: row; 
  }

  .p_gap_line {
    display: flex;
  }

  .p_other_page_link {
    padding-top: 0px; 
    padding-bottom: 0px;
  }
}