.footerSection {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
  padding-bottom: 10px;
}

.company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
}

.company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  /* color: #206191; */
  font-weight: bold;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  /*color: #858da8;*/
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.ti-facebook:before {
  content: "\e741";
}

.ti-twitter-alt:before {
  content: "\e74b";
}

.ti-vimeo-alt:before {
  content: "\e74a";
}

.ti-pinterest:before {
  content: "\e731";
}

.new_footer_top .f_social_icon a:hover {
  /*border-color: #5e2ced;*/
  color: white;
}

.new_footer_top .f_social_icon a+a {
  margin-left: 4px;
}

.new_footer_top .f-title {
  /* color: #263b5e; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4b505e;
}

.f_widget.about-widget .f_list li a {
  color: #6a7695;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

/* Sheru logo */

.div_footer_sheru_logo {
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-direction: row;
  align-items: center;
}

.div_sheru_logo_outer {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  padding-top: 5px;
  min-height: 45px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
}

.div_sheru_logo_inner {
  visibility: visible;
  animation-delay: 0.2s;
  animation-name: fadeInLeft;
  color: #6a7695;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
}

.div_sheru_logo_inner2 {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.img_sheru_lion {
  height: 45px;
  margin-left: 10px;
  width: auto;
}

.div_footer_copyright {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
}

.div_footer_social {
  display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.div_other_page_link_cover {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

/* Other page link container */

.otherLinkOuterContainer {
  display: flex;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otherLinkInnerContainer {
  margin: 0px;
  visibility: visible;
  animation-delay: 0.2s;
  animation-name: fadeInLeft;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.p_gap_line {
  width: 2px;
  height: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.p_other_page_link {
  font-size: 100%;
  font-family: outfitRegular;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: normal;
}

.p_other_page_link:hover {
  transform: scale(1.05);
}

/* Social media links */

.div_social_media_container_outer {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  padding-left: 0px;
  padding-right: 0px;
}

.div_social_media_container_inner {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  visibility: visible;
  animation-delay: 0.2s;
  animation-name: fadeInLeft;
}

.imageLink {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.imageLink:hover {
  transform: scale(1.10);
}

.anchorLink {
  text-decoration: none;
}

/* copyright */
.div_copyright_text {
  font-family: outfitRegular;
  display: flex;
  flex-direction: row;
  font-weight: normal;
  align-items: center;
  justify-content: right;
  min-height: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0px;
  padding-right: 0px;
}

.p_copyright_text {
  margin-left: 10px;
  font-family: outfitRegular;
  font-weight: lighter;
}

.f_social_icon {
  display: flex;
  flex-wrap: wrap;
}